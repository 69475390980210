import {
  Box,
  MenuItem,
  Pagination as MuiPagination,
  Typography,
  useTheme,
} from '@mui/material';
import PropTypes from 'prop-types';
import { TypographyElement, useContentElement } from '@plugins/next-cms-core';
import omitBy from 'lodash/omitBy';
import isNull from 'lodash/isNull';
import defaults from 'lodash/defaults';
import Container from '@components/atoms/Container';
import isNil from 'lodash/isNil';
import { gql, useQuery } from '@apollo/client';
import InventoryCarListItem, { GRAPHQL_REQUIRED_PROPS } from '@components/vehiclefleet/InventoryCarListItem';
import Select from '@components/atoms/Select';
import Hidden from '@components/atoms/Hidden';
import { FiSearch } from 'react-icons/fi';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

export default function SingleDayRegistrationsBlock(props) {
  const { data } = props;
  const theme = useTheme();
  const router = useRouter();
  const { t } = useTranslation();
  let filters = getFilterParams(router.query);

  const { elementData } = useContentElement(
    data,
    SingleDayRegistrationsBlock.dataSchema,
  );

  const {
    brand,
  } = elementData;

  const {
    error,
    data: dataQuery,
    loading,
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = useQuery(FETCH_ITEMS, {
    variables: {
      ...filters,
      brand,
      isDailyAdmission: true,
    },
    fetchPolicy: 'network-only',
  });

  if (error) {
    throw error;
  }

  const isLoading = loading;
  const items = dataQuery?.carSearchItems?.items ?? [];
  const totalCount = dataQuery?.carSearchItems?.totalCount ?? 0;
  filters = dataQuery?.carSearchItems?.filter ?? filters;
  const isPurchasingEnabled = Boolean(dataQuery?.carSearchConfig?.isPurchasingEnabled);

  let title = null;

  if (elementData.title) {
    title = omitBy(elementData.title, isNull);
    defaults(title, {
      semanticVariant: 'h3',
      displayVariant: 'h3',
      textAlign: 'center',
    });
  }

  const handleSetFilterConfiguration = (options = {}) => {
    const query = defaults(options, filters);
    // eslint-disable-next-line no-underscore-dangle
    delete query.__typename;
    setFilterParams(query, router);
  };
  const handleGoToPage = (event, page) => {
    const skip = (page - 1) * DEFAULT_ITEMS_PAGE_SIZE;
    const take = DEFAULT_ITEMS_PAGE_SIZE;

    handleSetFilterConfiguration({
      skip,
      take,
    });
  };

  const handleChangeSort = (event) => {
    const sort = event.target.value;
    handleSetFilterConfiguration({
      sort,
    });
  };

  return (
    <Box
      sx={{
        paddingTop: { xs: theme.spacing(5), md: theme.spacing(10) },
        paddingBottom: { xs: theme.spacing(5), md: theme.spacing(10) },
      }}
    >
      <Container>
        {title && (
          <Box mb={5}>
            <TypographyElement data={title} />
          </Box>
        )}
        <Pagination
          hidePagination
          onChangePaging={handleGoToPage}
          onChangeSort={handleChangeSort}
          skip={filters.skip}
          sort={filters.sort}
          t={t}
          take={filters.take}
          totalCount={totalCount}
        />
        <Items
          isPurchasingEnabled={isPurchasingEnabled}
          items={items}
          loading={isLoading}
          t={t}
          theme={theme}
        />
        <Pagination
          onChangePaging={handleGoToPage}
          onChangeSort={handleChangeSort}
          onlyPagination
          skip={filters.skip}
          sort={filters.sort}
          t={t}
          take={filters.take}
          totalCount={totalCount}
        />
      </Container>
    </Box>
  );
}

function Items(props) {
  const {
    t, loading, items, isPurchasingEnabled, theme,
  } = props;
  if (loading) {
    return (
      <Box
        sx={{
          marginTop: theme.spacing(5),
          marginBottom: theme.spacing(5),
          display: 'grid',
          columnGap: `${theme.spacing(3)}`,
          rowGap: `${theme.spacing(7)}`,
          gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
          [theme.breakpoints.up('md')]: {
            gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
          },
          [theme.breakpoints.up('lg')]: {
            gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
          },
        }}
      >
        <InventoryCarListItem useSkeleton />
        <InventoryCarListItem useSkeleton />
        <InventoryCarListItem useSkeleton />
      </Box>
    );
  }

  if (!items || items.length === 0) {
    return (
      <Box alignItems="center" display="flex" justifyContent="center" py={10}>
        <FiSearch
          style={{
            marginRight: theme.spacing(1),
            opacity: 0.5,
          }}
        />
        <Typography component="span" variant="body2">
          {t('pages.vehiclefleetapp.single-day-registrations.brand.noVehiclesFound')}
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
        display: 'grid',
        columnGap: `${theme.spacing(3)}`,
        rowGap: `${theme.spacing(7)}`,
        gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
        [theme.breakpoints.up('md')]: {
          gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
        },
        [theme.breakpoints.up('lg')]: {
          gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
        },
      }}
    >
      {items.map((item) => (
        <InventoryCarListItem
          key={item.id}
          isPurchasingEnabled={isPurchasingEnabled}
          item={item}
        />
      ))}
    </Box>
  );
}

function Pagination(props) {
  const {
    t,
    hidePagination,
    onlyPagination,
    sort,
    skip,
    take,
    totalCount,
    onChangePaging,
    onChangeSort,
  } = props;
  const page = Math.ceil(skip / take) + 1;
  const count = Math.ceil(totalCount / take);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      {!hidePagination
        ? (
          <Box>
            <Hidden dir="up" size="md">
              <MuiPagination
                count={count}
                onChange={onChangePaging}
                page={page}
                siblingCount={0}
              />
            </Hidden>
            <Hidden dir="down" size="sm">
              <MuiPagination
                boundaryCount={1}
                count={count}
                onChange={onChangePaging}
                page={page}
                siblingCount={1}
              />
            </Hidden>
          </Box>
        )
        : (
          <div />
        )}
      {!onlyPagination && (
        <Box alignItems="center" display="flex">
          <Box mr={3}>
            <Typography variant="button">
              {t('pages.vehiclefleetapp.single-day-registrations.brand.sortBy')}
            </Typography>
          </Box>
          <Select
            onChange={onChangeSort}
            value={sort}
            variant="outlined"
          >
            {SORT_OPTIONS.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {t(`pages.vehiclefleetapp.single-day-registrations.brand.sortOptions.${option.label}`)}
              </MenuItem>
            ))}
          </Select>
        </Box>
      )}
    </Box>
  );
}

SingleDayRegistrationsBlock.typeName = 'ComponentContentSingleDayRegistrations';
SingleDayRegistrationsBlock.propTypes = {
  data: PropTypes.shape({
    title: TypographyElement.propTypes,
    brand: PropTypes.string,
  }).isRequired,
};

SingleDayRegistrationsBlock.graphQlSchema = `
... on ${SingleDayRegistrationsBlock.typeName} {
  id
  title {
    ${TypographyElement.graphQlSchema}
  }
  brand
}
`;

function getFilterParams({ skip, take, sort }) {
  skip = Math.max(parseInt(skip ?? 0, 10), 0);
  take = Math.max(parseInt(take ?? DEFAULT_ITEMS_PAGE_SIZE, 10), DEFAULT_ITEMS_PAGE_SIZE);

  if (Number.isNaN(skip)) skip = 0;
  if (Number.isNaN(take)) take = DEFAULT_ITEMS_PAGE_SIZE;

  sort = sort ?? 'price:asc';

  return { skip, take, sort };
}

function setFilterParams(query, router) {
  const queryParams = new URLSearchParams(omitBy(query, isNil)).toString();
  const url = `${window.location.href.split('?')[0]}?${queryParams}`;

  window.scrollTo(0, 0);
  router.replace(url, undefined, { shallow: true });
}

const DEFAULT_ITEMS_PAGE_SIZE = 12;

const SORT_OPTIONS = [
  { value: 'price:asc', label: 'priceAscending' },
  { value: 'price:desc', label: 'priceDescending' },
];

const FETCH_ITEMS = gql`
  query GetInventoryCars($sort: String, $skip: Int, $take: Int, $brand: String, $isDailyAdmission: Boolean!) {
    carSearchItems(sort: $sort, skip: $skip, take: $take, brand: $brand, isDailyAdmission: $isDailyAdmission) {
      totalCount
      items {
        ${GRAPHQL_REQUIRED_PROPS}
      }
      filter {
        skip
        take
        sort
        brand
        isDailyAdmission
      }
    }
    carSearchConfig {
      data {
        id
        attributes {
          isPurchasingEnabled
        }
      }
    }
  }
`;
