import { Box, Grid2, useTheme } from '@mui/material';
import {
  ButtonElement, ColorScheme, TypographyElement, useContentElement,
} from '@plugins/next-cms-core';
import PropTypes from 'prop-types';
import defaults from 'lodash/defaults';
import isNull from 'lodash/isNull';
import omitBy from 'lodash/omitBy';
import Container from '../atoms/Container';

export default function HeroBlock(props) {
  const { data } = props;
  const theme = useTheme();
  const { elementData } = useContentElement(
    data,
    HeroBlock.dataSchema,
  );

  const { buttons } = elementData;
  let { colorScheme } = elementData;
  const title = omitBy(elementData.heroTitle, isNull);
  defaults(title, {
    semanticVariant: 'h3',
    displayVariant: 'h3',
  });

  const subtitle = omitBy(elementData.heroSubtitle, isNull);
  defaults(subtitle, {
    semanticVariant: 'h4',
    displayVariant: 'subtitle1',
  });

  const text = omitBy(elementData.heroText, isNull);
  defaults(text, {
    semanticVariant: 'div',
    displayVariant: 'body1',
    textAlign: 'justify',
  });

  colorScheme = ColorScheme.getColorScheme(colorScheme, 'secondary');
  const cssColorClass = `${colorScheme[0].toUpperCase()}${colorScheme.substring(1)}`;

  return (
    <Box
      sx={{
        overflowX: 'hidden',
        backgroundColor: getRootBgColor(cssColorClass, theme),
      }}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          paddingTop: { xs: theme.spacing(5), md: theme.spacing(10) },
          paddingBottom: { xs: theme.spacing(5), md: theme.spacing(10) },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              marginBottom: text.value ? 3 : 0,
            }}
          >
            <TypographyElement
              color={MUI_COLOR_MAPPING[colorScheme]}
              data={title}
              sx={{
                '& p': {
                  margin: 0,
                },
              }}
            />
            {subtitle.value && (
              <TypographyElement
                color={MUI_COLOR_MAPPING[colorScheme]}
                data={subtitle}
                sx={{
                  '& p': {
                    margin: 0,
                    fontWeight: 'bold',
                  },
                }}
              />
            )}
          </Box>
          {text.value && (
            <Grid2
              container
              sx={{
                justifyContent: 'center',
                [theme.breakpoints.up('md')]: {
                  paddingLeft: theme.spacing(9),
                },
              }}
            >
              <Grid2 size={{ lg: 9, md: 10, xs: 12 }}>
                <TypographyElement
                  color={MUI_COLOR_MAPPING[colorScheme]}
                  data={text}
                />
              </Grid2>
            </Grid2>
          )}
          {buttons && buttons.length > 0 && (
            <Box sx={{ marginTop: 3 }}>
              <Grid2
                container
                spacing={3}
                sx={{
                  justifyContent: 'center',
                }}
              >
                {buttons.map((button) => (
                  <Grid2
                    key={button.id}
                    size={{ xs: 12, md: 'auto' }}
                  >
                    <ButtonElement
                      color={MUI_COLOR_MAPPING[colorScheme]}
                      component="a"
                      data={button}
                      // endIcon={<FiArrowRight />}
                      fullWidth
                      size="large"
                      variant="outlined"
                    />
                  </Grid2>
                ))}
              </Grid2>
            </Box>
          )}
        </Box>
      </Container>
    </Box>
  );
}

function getRootBgColor(cssColorClass, theme) {
  switch (cssColorClass) {
    case 'Primary':
      return theme.palette.secondary.main;
    case 'Secondary':
      return theme.palette.primary.main;
    case 'Tertiary':
      return theme.palette.tertiary.main;
    case 'Quaternary':
      return theme.palette.quaternary.main;
    case 'Light':
      return theme.palette.light.main;
    case 'Dark':
      return theme.palette.dark.main;
    default:
      return 'inherit';
  }
}

HeroBlock.typeName = 'ComponentContentHero'; // Strapi element type
HeroBlock.propTypes = {
  data: PropTypes.shape({
    title: TypographyElement.propTypes,
    subtitle: TypographyElement.dataSchema,
    text: TypographyElement.propTypes,
    buttons: PropTypes.arrayOf(PropTypes.shape(ButtonElement.propTypes)),
    colorScheme: ColorScheme.propTypes,
  }).isRequired,
};

HeroBlock.graphQlSchema = `
... on ${HeroBlock.typeName} {
  id
  heroTitle: title {
    ${TypographyElement.graphQlSchema}
  }
  heroSubtitle: subtitle {
    ${TypographyElement.graphQlSchema}
  }
  heroText: text {
    ${TypographyElement.graphQlSchema}
  }
  buttons {
    ${ButtonElement.graphQlSchema}
  }
  colorScheme {
    ${ColorScheme.graphQlSchema}
  }
}
`;

const MUI_COLOR_MAPPING = {
  default: 'inherit',
  primary: 'primary',
  secondary: 'secondary',
  tertiary: 'inherit',
  quaternary: 'inherit',
  light: 'inherit',
  dark: 'secondary',
};
