/* eslint-disable react/no-array-index-key */
import {
  Accordion, AccordionDetails, AccordionSummary, Box, Grid2, Typography, useTheme,
} from '@mui/material';
import {
  ButtonElement, MediaElement, TypographyElement, useContentElement,
} from '@plugins/next-cms-core';
import React, { useState } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import PropTypes from 'prop-types';
import omitBy from 'lodash/omitBy';
import isNull from 'lodash/isNull';
import defaults from 'lodash/defaults';
import Container from '../atoms/Container';
import ReadMoreContainer from '../atoms/ReadMoreContainer';

export default function AccordionSliderBlock(props) {
  const { data } = props;
  const theme = useTheme();
  const [expandedPanel, setExpandedPanel] = useState(0);
  const { elementData } = useContentElement(
    data,
    AccordionSliderBlock.dataSchema,
  );

  const { accordionSliderSlides } = elementData;
  let title = null;

  if (elementData.title) {
    title = omitBy(elementData.title, isNull);
    defaults(title, {
      semanticVariant: 'h3',
      displayVariant: 'h3',
      textAlign: 'center',
    });
  }

  if (!accordionSliderSlides || accordionSliderSlides.length === 0) {
    return null;
  }

  const currentSlide = accordionSliderSlides[expandedPanel];

  const handleExpandPanel = (index) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? index : 0);
  };

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
      }}
    >
      {title && (
        <TypographyElement data={title} mb={5} />
      )}
      <Grid2
        container
        spacing={3}
        sx={{
          justifyContent: 'center',
        }}
      >
        <Grid2 size={{
          md: 7,
          xs: 12,
        }}
        >
          <MediaElement data={currentSlide.media} />
        </Grid2>
        <Grid2 size={{
          md: 5,
          xs: 12,
        }}
        >
          {accordionSliderSlides.map((item, itemIndex) => {
            let itemSuffix = null;
            if (item.itemSuffix) {
              itemSuffix = omitBy(item.itemSuffix, isNull);
              defaults(itemSuffix, {
                semanticVariant: 'h3',
                displayVariant: 'subtitle2',
              });
            }

            let itemCategory = null;
            if (item.itemCategory) {
              itemCategory = omitBy(item.itemCategory, isNull);
              defaults(itemCategory, {
                semanticVariant: 'h3',
                displayVariant: 'subtitle2',
              });
            }

            let itemTitle = null;
            if (item.itemTitle) {
              itemTitle = omitBy(item.itemTitle, isNull);
              defaults(itemTitle, {
                semanticVariant: 'h3',
                displayVariant: 'h3',
              });
            }

            return (
              <Accordion
                key={itemIndex}
                expanded={expandedPanel === itemIndex}
                onChange={handleExpandPanel(itemIndex)}
                slotProps={{
                  heading: {
                    component: 'div',
                  },
                }}
              >
                <AccordionSummary expandIcon={<FiChevronDown />}>
                  {itemCategory?.value && (
                    <TypographyElement data={itemCategory} sx={{ marginRight: 'auto' }} />
                  )}
                  {itemSuffix?.value && expandedPanel !== itemIndex && (
                    <TypographyElement data={itemSuffix} />
                  )}
                </AccordionSummary>
                <AccordionDetails>
                  <Box>
                    {itemTitle?.value && (
                      <TypographyElement
                        data={itemTitle}
                        sx={{
                          mb: 3,
                          '& > p': {
                            margin: 0,
                          },
                        }}
                      />
                    )}
                    <ReadMoreContainer
                      data={item}
                      expandedPanel={expandedPanel}
                      itemIndex={itemIndex}
                      suffix={item.suffix}
                    />
                  </Box>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Grid2>
      </Grid2>
    </Container>
  );
}

AccordionSliderBlock.typeName = 'ComponentContentAccordion'; // Strapi element type
AccordionSliderBlock.propTypes = {
  data: PropTypes.shape({
    title: TypographyElement.propTypes,
    accordionSliderSlides: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string,
      category: PropTypes.string.isRequired,
      media: MediaElement.propTypes,
      text: TypographyElement.propTypes,
    })),
  }).isRequired,
};

AccordionSliderBlock.graphQlSchema = `
... on ${AccordionSliderBlock.typeName} {
  id
  title {
    ${TypographyElement.graphQlSchema}
  }
  accordionSliderSlides: slides {
    id
    category
    suffix
    media {
      ${MediaElement.graphQlSchema}
    }
    title
    text {
      ${TypographyElement.graphQlSchema}
    }
    buttons {
      ${ButtonElement.graphQlSchema}
    }
    itemTitle {
      ${TypographyElement.graphQlSchema}
    }
    itemSuffix {
      ${TypographyElement.graphQlSchema}
    }
    itemCategory {
      ${TypographyElement.graphQlSchema}
    }
  }
}
`;
