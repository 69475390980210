import { Box, Grid2, useTheme } from '@mui/material';
import { ColorScheme, TypographyElement, useContentElement } from '@plugins/next-cms-core';
import PropTypes from 'prop-types';
import defaults from 'lodash/defaults';
import isNull from 'lodash/isNull';
import omitBy from 'lodash/omitBy';
import Container from '../atoms/Container';

export default function TextBlock(props) {
  const { data } = props;
  const theme = useTheme();
  const { elementData } = useContentElement(
    data,
    TextBlock.dataSchema,
  );

  const {
    textBlockTextColumns,
    colorScheme,
  } = elementData;

  const title = omitBy(elementData.textBlockTitle, isNull);
  defaults(title, {
    semanticVariant: 'h3',
    displayVariant: 'h3',
  });

  const isSingleColumn = textBlockTextColumns.length === 1;
  const columnSpread = Math.floor(12 / textBlockTextColumns.length);
  const textBlockColorScheme = ColorScheme.getColorScheme(colorScheme, 'initial');
  const cssColorClass = `${textBlockColorScheme[0].toUpperCase()}${textBlockColorScheme.substring(1)}`;
  const textColor = getTextColor(cssColorClass, theme);

  return (
    <Box
      sx={{
        backgroundColor: getRootBackgroundColor(cssColorClass, theme),
      }}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          paddingTop: theme.spacing(2),
          paddingBottom: theme.spacing(2),
          [theme.breakpoints.up('lg')]: {
            paddingTop: theme.spacing(8),
            paddingBottom: theme.spacing(8),
          },
        }}
      >
        <Grid2 container justifyContent="center">
          <Grid2
            size={{
              lg: isSingleColumn ? 9 : 12,
              md: isSingleColumn ? 10 : 12,
            }}
          >
            {title?.value && (
              <Box mb={5}>
                <TypographyElement
                  color={textColor}
                  data={title}
                  sx={{
                    '& p': {
                      margin: 0,
                    },
                  }}
                />
              </Box>
            )}
            <Grid2
              container
              spacing={5}
              sx={{
                ...(isSingleColumn && ({
                  [theme.breakpoints.up('md')]: {
                    paddingLeft: theme.spacing(9),
                  },
                })),
              }}
            >
              {textBlockTextColumns.map((element) => {
                const text = omitBy(element, isNull);
                defaults(text, {
                  semanticVariant: 'div',
                  displayVariant: 'body1',
                  textAlign: 'justify',
                });

                return (
                  <Grid2
                    key={element.id}
                    size={{
                      md: columnSpread,
                      xs: 12,
                    }}
                  >
                    <TypographyElement color={textColor} data={text} />
                  </Grid2>
                );
              })}
            </Grid2>
          </Grid2>
        </Grid2>
      </Container>
    </Box>
  );
}

function getRootBackgroundColor(variant, theme) {
  switch (variant) {
    case 'Primary':
      return theme.palette.secondary.main;
    case 'Secondary':
      return theme.palette.primary.main;
    case 'Tertiary':
      return theme.palette.tertiary.main;
    case 'Quaternary':
      return theme.palette.quaternary.main;
    default:
      return 'transparent';
  }
}

function getTextColor(variant, theme) {
  switch (variant) {
    case 'Primary':
      return theme.palette.secondary.contrastText;
    case 'Secondary':
      return theme.palette.primary.contrastText;
    case 'Tertiary':
      return theme.palette.tertiary.contrastText;
    case 'Quaternary':
      return theme.palette.quaternary.contrastText;
    default:
      return theme.palette.text.primary;
  }
}

TextBlock.typeName = 'ComponentContentText'; // Strapi element type
TextBlock.propTypes = {
  data: PropTypes.shape({
    title: TypographyElement.propTypes,
    textColumns: PropTypes.arrayOf(PropTypes.shape(TypographyElement.propTypes)),
    colorScheme: ColorScheme.propTypes,
  }).isRequired,
};

TextBlock.graphQlSchema = `
... on ${TextBlock.typeName} {
  id
  textBlockTitle: title {
    ${TypographyElement.graphQlSchema}
  }
  textBlockTextColumns: text {
    ${TypographyElement.graphQlSchema}
  }
  colorScheme {
    ${ColorScheme.graphQlSchema}
  }
}
`;
