import {
  Box, Button, Grid2, Paper, Typography, useTheme,
} from '@mui/material';
import { TypographyElement, useContentElement } from '@plugins/next-cms-core';
import { FiShoppingCart } from 'react-icons/fi';
import PropTypes from 'prop-types';
import defaults from 'lodash/defaults';
import { getCarPartsShopRoute } from '@lib/routing';
import isNull from 'lodash/isNull';
import omitBy from 'lodash/omitBy';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import Link from '../atoms/Link';
import Container from '../atoms/Container';

export default function CarPartsShopBlock(props) {
  const { data } = props;
  const theme = useTheme();
  const router = useRouter();
  const { i18n, t } = useTranslation();
  const { elementData } = useContentElement(
    data,
    CarPartsShopBlock.dataSchema,
  );

  const {
    dealerid,
    series,
  } = elementData;
  let title = null;

  if (elementData.title) {
    title = omitBy(elementData.title, isNull);
    defaults(title, {
      semanticVariant: 'h3',
      displayVariant: 'h3',
    });
  }

  const url = getCarPartsShopRoute({
    dealerid: dealerid ? dealerid.substring(0, dealerid.indexOf('_')) : null,
    series,
    locale: i18n.language,
  });
  let image = 'carpartsshop-teaser';

  if (router.asPath) {
    if (router.asPath.startsWith('/hyundai')) {
      image = 'carpartsshop-teaser-hyundai';
    } else if (router.asPath.startsWith('/mitsubishi')) {
      image = 'carpartsshop-teaser-mitsubishi';
    }
  }

  return (
    <Box
      sx={{
        overflowX: 'hidden',
        paddingTop: { xs: theme.spacing(5), md: theme.spacing(10) },
        paddingBottom: { xs: theme.spacing(5), md: theme.spacing(10) },
      }}
    >
      <Container>
        <Paper elevation={10}>
          <Grid2 alignItems="stretch" container>
            <Grid2
              size={{ md: 6, xs: 12 }}
              sx={{
                display: 'flex',
                overflow: 'hidden',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center center',
                [theme.breakpoints.down('sm')]: {
                  minHeight: 300,
                  borderTopLeftRadius: theme.shape.borderRadius,
                  borderTopRightRadius: theme.shape.borderRadius,
                },
                [theme.breakpoints.up('md')]: {
                  borderTopLeftRadius: theme.shape.borderRadius,
                  borderBottomLeftRadius: theme.shape.borderRadius,
                },
                backgroundImage: `url("/images/${image}.jpg")`,
              }}
            />
            <Grid2
              sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                padding: theme.spacing(5),
              }}
            >
              {title && (
                <Box mb={3}>
                  <TypographyElement data={title} />
                </Box>
              )}
              <Box mb={5}>
                <Typography
                  dangerouslySetInnerHTML={{
                    __html: t('components.contentTypes.CarPartsShopBlock.carPartsShopText'),
                  }}
                />
                <Typography>
                  <br />
                  <br />
                  <strong>
                    {t('components.contentTypes.CarPartsShopBlock.carPartsShopText2')}
                  </strong>
                </Typography>
              </Box>
              <Box>
                <Link href={url} legacyBehavior>
                  <Button
                    color="primary"
                    component="a"
                    startIcon={<FiShoppingCart />}
                    variant="contained"
                  >
                    {t('components.contentTypes.CarPartsShopBlock.partsShop')}
                  </Button>
                </Link>
              </Box>
            </Grid2>
          </Grid2>
        </Paper>
      </Container>
    </Box>
  );
}

CarPartsShopBlock.typeName = 'ComponentContentCarpartsshop'; // Strapi element type
CarPartsShopBlock.propTypes = {
  data: PropTypes.shape({
    title: TypographyElement.propType,
    dealerid: PropTypes.string,
    series: PropTypes.string,
    model: PropTypes.string,
    hsn: PropTypes.string,
    tsn: PropTypes.string,
    fin: PropTypes.string,
    currencycode: PropTypes.string,
    display: PropTypes.string,
  }).isRequired,
};

CarPartsShopBlock.graphQlSchema = `
... on ${CarPartsShopBlock.typeName} {
  id
  title {
    ${TypographyElement.graphQlSchema}
  }
  dealerid
  series
  model
  hsn
  tsn
  fin
  currencycode
  display
}
`;
