import {
  Box,
  Checkbox,
  FormHelperText,
  ListItemText,
  MenuItem,
  Typography,
  useTheme,
} from '@mui/material';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import Select from '../../../../atoms/Select';

export default function DropDown(props) {
  const {
    name,
    disabled,
    value = '',
    error = '',
    touched,
    label = '',
    isMultiple,
    options = [],
    handleBlur,
    handleChange,
    helperText = '',
    readOnly,
  } = props;

  return (
    <>
      <Select
        disabled={disabled}
        error={error && touched}
        label={label}
        labelId={`${name}-label`}
        multiple={isMultiple}
        name={name}
        onBlur={handleBlur}
        onChange={(event) => handleChange(event.target.value)}
        readOnly={readOnly}
        renderValue={
          isMultiple
            ? (selected) => (
              <Typography variant="subtitle1">
                {selected.map((selectedValue) => get(options.find((o) => (o.value || o.label) === selectedValue), 'label')).filter((x) => x).join(', ')}
              </Typography>
            )
            : (selectedValue) => (
              <Typography variant="subtitle1">
                {get(options.find((o) => (o.value || o.label) === selectedValue), 'label')}
              </Typography>
            )
        }
        size="small"
        value={isMultiple && !Array.isArray(value) ? [] : value}
      >
        {options?.map((item) => (
          <MenuItem
            key={item.id}
            disabled={Boolean(item.isDisabled)}
            sx={isMultiple ? { py: 0 } : null}
            value={item.value || item.label_de}
          >
            {isMultiple && (
              <Checkbox checked={(value ?? []).indexOf(item.value || item.label_de) > -1} />
            )}
            <ListItemText
              primary={item.label}
              primaryTypographyProps={{ variant: 'body2' }}
            />
          </MenuItem>
        ))}
      </Select>
      {(error || helperText) && (
        <FormHelperText error={error} sx={{ ml: 1.7 }}>
          {error || helperText}
        </FormHelperText>
      )}
    </>
  );
}

DropDown.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  error: PropTypes.string,
  touched: PropTypes.bool,
  label: PropTypes.string,
  isMultiple: PropTypes.bool,
  options: PropTypes.array,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  helperText: PropTypes.string,
  readOnly: PropTypes.bool,
};
