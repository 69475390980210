import { Box, Button, useTheme } from '@mui/material';
import { TypographyElement, useContentElement } from '@plugins/next-cms-core';
import { gql, useLazyQuery } from '@apollo/client';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import defaults from 'lodash/defaults';
import { getBlogRoute } from '@lib/routing';
import isNull from 'lodash/isNull';
import omitBy from 'lodash/omitBy';
import Slider from '@components/atoms/Slider';
import { useInView } from 'react-intersection-observer';
import Link from '../atoms/Link';
import Container from '../atoms/Container';
import BlogPreviewItem from '../molecules/BlogPreviewItem';

const PAGE_QUERY_PARAM = 'bsp';

export default function BlogBlock(props) {
  const { data } = props;
  const router = useRouter();
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const [swiper, setSwiper] = useState();
  const [ref, inView] = useInView();
  const { elementData } = useContentElement(
    data,
    BlogBlock.dataSchema,
  );

  // Additional data
  const [
    fetchData,
    { error, data: additionalData },
  ] = useLazyQuery(FETCH_ADDITIONAL_DATA, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    variables: {
      maxItems: elementData.maxItems ?? 5,
      locale: i18n.language,
    },
    onCompleted: () => {
      const initialSlide = getInitialSlide();

      if (initialSlide > 0) {
        swiper.slideTo(initialSlide);
      }
    },
  });

  const slideIndex = parseInt(router.query[PAGE_QUERY_PARAM] ?? 0, 10);

  useEffect(() => {
    if (!swiper || !additionalData) return;
    swiper.slideTo(slideIndex);
  }, [slideIndex, swiper, additionalData]);

  if (error) {
    throw error;
  }

  const {
    blogDisplay: display,
  } = elementData;

  let title = null;

  if (elementData.title) {
    title = omitBy(elementData.title, isNull);
    defaults(title, {
      semanticVariant: 'h3',
      displayVariant: 'h3',
      textAlign: 'center',
    });
  }

  const {
    pages,
  } = additionalData ?? {
    pages: {},
  };

  useEffect(() => {
    if (inView) {
      fetchData();
    }
  }, [inView, fetchData]);

  return (
    <Box
      ref={ref}
      sx={{
        overflowX: 'hidden',
        paddingTop: { xs: theme.spacing(5), md: theme.spacing(10) },
        paddingBottom: { xs: theme.spacing(5), md: theme.spacing(10) },
      }}
    >
      <Container>
        {title && (
          <Box mb={5}>
            <TypographyElement data={title} />
          </Box>
        )}
        {display !== 'slider' && <List items={pages.data ?? []} />}
        {display === 'slider' && (
          <Box sx={{ position: 'relative' }}>
            <Slider
              autoHeight
              items={pages.data ?? []}
              pageQueryParam={PAGE_QUERY_PARAM}
              setSwiper={setSwiper}
              SlideComponent={BlogPreviewItem}
              sliderStyles={{
                paddingTop: 20,
                marginBottom: 20,
              }}
              swiper={swiper}
              swiperSlideStyles={{
                height: 'auto',
                display: 'flex',
              }}
            />
          </Box>
        )}
        <Box display="flex" justifyContent="center">
          <Link href={getBlogRoute()} legacyBehavior>
            <Button
              color="primary"
              component="a"
              variant="contained"
            >
              {t('components.contentTypes.BlogBlock.moreArticles')}
            </Button>
          </Link>
        </Box>
      </Container>
    </Box>
  );
}

function List(props) {
  const {
    items,
  } = props;

  return items.map((item) => (
    <Box
      key={item.id}
      mb={5}
    >
      <BlogPreviewItem item={item} />
    </Box>
  ));
}

function getInitialSlide() {
  const queryParams = new URLSearchParams(window.location.search);
  if (queryParams.has(PAGE_QUERY_PARAM)) {
    return parseInt(queryParams.get(PAGE_QUERY_PARAM) ?? 0, 10);
  }
  return 0;
}

BlogBlock.typeName = 'ComponentContentBlog'; // Strapi element type
BlogBlock.propTypes = {
  data: PropTypes.shape({
    title: TypographyElement.propTypes,
    maxItems: PropTypes.number,
    blogDisplay: PropTypes.oneOf([null, 'list', 'slider']),
  }).isRequired,
};

BlogBlock.graphQlSchema = `
... on ${BlogBlock.typeName} {
  id
  title {
    ${TypographyElement.graphQlSchema}
  }
  blogDisplay: display
  maxItems
}
`;

const FETCH_ADDITIONAL_DATA = gql`
query GetBlogItems($locale: I18NLocaleCode!, $maxItems: Int) {
  pages(
    filters: {
      pageType: { in: ["blogItem", "campaignItem"] }
    },
    sort: ["isPinnedToTop:desc", "publishDate:desc", "publishedAt:desc"]
    locale: $locale,
    pagination: { start: 0, limit: $maxItems }
  ) {
    data {
      ${BlogPreviewItem.graphQlSchema}
    }
  }
}
`;
