import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardContent,
  Grid2,
  Typography,
  Skeleton,
} from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useTranslation } from 'next-i18next';

export default function Calendar(props) {
  const {
    t, data, setData, onGoNext,
  } = props;
  const { i18n } = useTranslation();

  const { error, data: calendarData, loading } = useQuery(FETCH_CALENDARS, {
    variables: {
      branchId: data.branch.data?.id ?? data.branch.id,
      locale: i18n.language,
    },
  });

  useEffect(() => {
    if (!data.calendarCount && calendarData && calendarData.workshopCalendars.data.length === 1) {
      handleSelectCalendar(calendarData.workshopCalendars.data[0].id)();
    }
  }, [calendarData]);

  if (error) {
    throw error;
  }

  if (loading) {
    return (
      <Skeleton />
    );
  }

  const calendars = calendarData?.workshopCalendars.data ?? [];

  const handleSelectCalendar = (id) => () => {
    const calendar = calendars.find((c) => c.id === id);

    setData({
      ...data,
      calendarCount: calendars.length,
      calendarId: id,
      calendar,
      categories: [],
      selectedServices: [],
      selectedDate: null,
      selectedTime: null,
    });
    onGoNext();
  };

  return (
    <div>
      <Box mb={3}>
        <Typography component="p" variant="h6">
          {t('components.organisms.WorkShopAppointmentTool.steps.Calendar.question')}
        </Typography>
      </Box>
      <Grid2 container spacing={3}>
        {calendars.map((calendar) => (
          <Grid2 key={calendar.id} size={{ sm: 6, xs: 12 }}>
            <Item
              calendar={calendar}
              onSelect={handleSelectCalendar}
            />
          </Grid2>
        ))}
      </Grid2>
    </div>
  );
}

function Item(props) {
  const {
    calendar,
    onSelect,
  } = props;

  return (
    <Card>
      <CardActionArea
        data-e2e-id="calendar"
        data-e2e-value={calendar.id}
        onClick={onSelect(calendar.id)}
      >
        <CardContent>
          <Box alignItems="center" display="flex">
            {calendar.attributes.icon && (
              <Box mr={2}>
                <Avatar
                  imgProps={{
                    style: { objectFit: 'contain' },
                  }}
                  src={calendar.attributes.icon?.data?.attributes.formats?.small?.url
                    ?? calendar.attributes.icon?.data?.attributes.formats?.thumbnail?.url
                      ?? calendar.attributes.icon?.data?.attributes.url}
                  variant="square"
                />
              </Box>
            )}
            <Typography component="div" variant="subtitle1">
              {calendar.attributes.name}
            </Typography>
          </Box>
          {calendar.attributes.description && (
            <Box mt={1}>
              <Typography variant="body2">
                {calendar.attributes.description}
              </Typography>
            </Box>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

Calendar.validateStep = function (data) {
  return Boolean(data.calendarId);
};

const FETCH_CALENDARS = gql`
query GetItems($branchId: ID!, $locale: I18NLocaleCode!) {
  workshopCalendars(
    sort: ["index", "name"],
    filters: { branch: { id: { eq: $branchId } } },
    locale: $locale
  ) {
    data {
      id
      attributes {
        name
        description
        icon {
          data {
            id
            attributes {
              name
              formats
              url
            }
          }
        }
      }
    }
  }
}
`;
