/* eslint-disable react/no-array-index-key */
import {
  Accordion, AccordionDetails, AccordionSummary, Box, Grid2, Typography, useTheme,
} from '@mui/material';
import { TypographyElement, useContentElement } from '@plugins/next-cms-core';
import React, { useEffect, useRef, useState } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import isNull from 'lodash/isNull';
import defaults from 'lodash/defaults';
import Container from '../atoms/Container';

export default function FaqBlock(props) {
  const { data } = props;
  const theme = useTheme();
  const [minHeight, setMinHeight] = useState(100);
  const rootRef = useRef();

  useEffect(() => {
    if (!rootRef.current) {
      return;
    }

    setMinHeight(rootRef.current.clientHeight);
  }, []);

  const { elementData } = useContentElement(
    data,
    FaqBlock.dataSchema,
  );

  const {
    faqTitle,
    faqs,
  } = elementData;
  const items = faqs.data.flatMap((f) => f.attributes.items);

  const title = omitBy(elementData.title2, isNull);
  defaults(title, {
    semanticVariant: 'h3',
    displayVariant: 'h3',
    textAlign: 'center',
    value: faqTitle || 'Sie haben Fragen - wir haben Antworten',
  });

  return (
    <Box
      ref={rootRef}
      sx={{
        overflowX: 'hidden',
        paddingTop: {
          xs: theme.spacing(5),
          md: theme.spacing(10),
        },
        paddingBottom: {
          xs: theme.spacing(5),
          md: theme.spacing(10),
        },
        backgroundColor: theme.palette.text.hint,
        minHeight,
      }}
    >
      <Container>
        <Box mb={5}>
          <TypographyElement data={title} />
        </Box>
        <Grid2 container spacing={3}>
          {items.map((item, index) => (
            <Grid2
              key={index}
              size={{
                md: 6,
                xs: 12,
              }}
            >
              <Accordion
                slotProps={{
                  heading: {
                    component: 'div',
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<FiChevronDown />}
                  variant="subtitle2"
                >
                  {item.question}
                </AccordionSummary>
                <AccordionDetails>
                  <TypographyElement data={item?.answer} />
                </AccordionDetails>
              </Accordion>
            </Grid2>
          ))}
        </Grid2>
      </Container>
    </Box>
  );
}

FaqBlock.typeName = 'ComponentContentFaq'; // Strapi element type
FaqBlock.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    faqs: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      items: PropTypes.arrayOf(PropTypes.shape({
        question: PropTypes.string.isRequired,
        answer: TypographyElement.propTypes,
      })),
    })),
  }).isRequired,
};

FaqBlock.graphQlSchema = `
... on ${FaqBlock.typeName} {
  id
  faqTitle: title
  title2 {
    ${TypographyElement.graphQlSchema}
  }
  faqs {
    data {
      id
      attributes {
        items {
          id
          question
          answer {
            ${TypographyElement.graphQlSchema}
          }
        }
      }
    }
  }
}
`;
