import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useTranslation } from 'next-i18next';

export default function DatePickerProvider({ children }) {
  const { i18n } = useTranslation();

  return (
    <LocalizationProvider adapterLocale={i18n.language} dateAdapter={AdapterLuxon}>
      {children}
    </LocalizationProvider>
  );
}
